// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import App from './App'
import router from './router'
import apolloClient from './apollo'
import store from './store'
import VueApollo from 'vue-apollo'
import moment from 'moment'
import VueMoment from 'vue-moment'
import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.css'
import VTooltip from 'v-tooltip'
import VueAutosize from 'vue-autosize'
import {API_INIT_CSRF} from "@/config";

Vue.use(VueAutosize)
Vue.use(VTooltip)
Vue.use(Buefy, {
  defaultDialogCancelText: 'Abbrechen'
})

moment.locale('de')
Vue.use(VueMoment, {moment})

Vue.use(VueApollo)
Vue.config.productionTip = false

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

Vue.directive('parseInt', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      let val = parseInt(e.target.value);
      vnode.componentInstance.$emit('input', isNaN(val) ? null : val)
    })
  }
})


Vue.filter('yesno', bool => bool ? 'Ja' : 'Nein')
Vue.filter('cut', (val, length) => val.substr(0, length))

fetch(API_INIT_CSRF, {credentials: 'include', mode: 'cors'}).then(async () => {

  /* eslint-disable no-new */
  new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
  }).$mount('#app')
})