import moment from 'moment'

export default {
  state: {
    adminView: true,
    showNotifications: false,
    Calendar: {
      hideOfferingIds: [],
      listView: false,
      showDate: new Date()
    }
  },
  mutations: {
    calendarAddMonth(state, amount) {
      state.Calendar.showDate = moment(state.Calendar.showDate).add(amount, 'M').toDate()
    },
    calendarGoToday(state) {
      state.Calendar.showDate = new Date()
    },
    toggleListView(state) {
      state.Calendar.listView = !state.Calendar.listView
    },
    showListView(state) {
      state.Calendar.listView = true
    },
    showCalendarView(state) {
      state.Calendar.listView = false
    },
    toggleOfferingId(state, id) {
      let index = state.Calendar.hideOfferingIds.indexOf(id)
      if (index === -1) {
        state.Calendar.hideOfferingIds.push(id)
      } else {
        state.Calendar.hideOfferingIds.splice(index, 1)
      }
    },
    toggleNotifications(state) {
      state.showNotifications = !state.showNotifications
    }
  },
  getters: {
    calendarBegin: (state) => {
      return moment(state.Calendar.showDate).startOf('month').startOf('isoWeek').toDate()
    },
    calendarEnd: (state) => {
      return moment(state.Calendar.showDate).endOf('month').endOf('isoWeek').toDate()
    }
  }
}
