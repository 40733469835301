import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

const Login = () => import('@/components/Auth/Login')
const Register = () => import('@/components/Auth/Register')
const PasswordReset = () => import('@/components/Auth/PasswordReset')
const SendPasswordReset = () => import('@/components/Auth/SendPasswordReset')

const BasicLayout = () => import(/* webpackChunkName: "loggedIn" */ '@/components/BasicLayout')
const CalendarPage = () => import(/* webpackChunkName: "loggedIn" */ '@/components/Pages/Calendar')
const DashboardPage = () => import(/* webpackChunkName: "loggedIn" */ '@/components/Pages/Dashboard')
const EventPage = () => import(/* webpackChunkName: "loggedIn" */ '@/components/Pages/Event')
const BlockingEventPage = () => import('@/components/Pages/BlockingEvent')
const CreateBlockingEventPage = () => import('@/components/Pages/CreateBlockingEvent')
const CreateEventPage = () => import('@/components/Pages/CreateEvent')

const SettingsPage = () => import('@/components/Pages/Settings')
const SettingsOverviewPage = () => import('@/components/Pages/Settings/Overview')
const SettingsUsersPage = () => import('@/components/Pages/Settings/Users')
const SettingsUserDetailPage = () => import('@/components/Pages/Settings/UserDetail')
const SettingsOfferingsPage = () => import('@/components/Pages/Settings/Offerings')
const SettingsOfferingDetailPage = () => import('@/components/Pages/Settings/OfferingDetail')
const SettingsMailsPage = () => import('@/components/Pages/Settings/Mails')

const UserSettingsPage = () => import('@/components/Pages/UserSettings')

const Statistics = () => import('@/components/Pages/Statistics')
// const ImprintPage = () => import('@/components/Pages/Imprint')
// const PrivacyPage = () => import('@/components/Pages/Privacy')

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/password/reset/:token',
      name: 'PasswordReset',
      props: true,
      component: PasswordReset
    },
    {
      path: '/password/email',
      name: 'SendPasswordReset',
      component: SendPasswordReset
    },
    {
      path: '/',
      name: 'BasicLayout',
      component: BasicLayout,
      meta: {'requiresAuth': true},
      redirect: 'dashboard',
      children: [
        /* {
          path: 'imprint',
          name: 'Imprint',
          component: ImprintPage,
          meta: {title: 'Impressum'}
        },
        {
          path: 'privacy',
          name: 'Privacy',
          component: PrivacyPage,
          meta: {title: 'Datenschutz'}
        }, */
        {
          path: 'usersettings',
          name: 'UserSettings',
          component: UserSettingsPage,
          meta: {'requiresAuth': true, title: 'Benutzereinstellungen'}
        },
        {
          path: 'statistics',
          name: 'Statistics',
          component: Statistics,
          meta: {'requiresAuth': true, title: 'Statistiken'}
        },
        {
          path: 'settings',
          name: 'Settings',
          component: SettingsPage,
          meta: {'requiresAuth': true, title: 'Einstellungen'},
          redirect: {'name': 'SettingsOverview'},
          children: [
            {
              path: 'overview',
              name: 'SettingsOverview',
              component: SettingsOverviewPage,
              meta: {'requiresAuth': true, title: 'Einstellungen'}
            },
            {
              path: 'users',
              name: 'SettingsUsers',
              component: SettingsUsersPage,
              meta: {'requiresAuth': true, title: 'Einstellungen'}
            },
            {
              path: 'users/:id',
              name: 'SettingsUserDetail',
              props: true,
              component: SettingsUserDetailPage,
              meta: {'requiresAuth': true, title: 'Einstellungen'}
            },
            {
              path: 'offerings',
              name: 'SettingsOfferings',
              component: SettingsOfferingsPage,
              meta: {'requiresAuth': true, title: 'Einstellungen'}
            },
            {
              path: 'offerings/:id',
              name: 'SettingsOfferingDetail',
              props: true,
              component: SettingsOfferingDetailPage,
              meta: {'requiresAuth': true, title: 'Einstellungen'}
            },
            {
              path: 'mails',
              name: 'SettingsMails',
              component: SettingsMailsPage,
              meta: {'requiresAuth': true, title: 'Einstellungen'}
            }
          ]
        },
        {
          path: 'calendar',
          name: 'Calendar',
          component: CalendarPage,
          meta: {'requiresAuth': true, title: 'Kalender'},
          children: [
            {
              path: 'event/:id',
              name: 'event',
              props: true,
              component: EventPage,
              meta: {'requiresAuth': true, title: 'Kalender'}
            },
            {
              path: 'createevent',
              name: 'createevent',
              component: CreateEventPage,
              meta: {'requiresAuth': true, title: 'Veranstaltung Erstellen'}
            },
            {
              path: 'blockingevent/:id',
              name: 'blockingevent',
              props: true,
              component: BlockingEventPage,
              meta: {'requiresAuth': true, title: 'Kalender'}
            },
            {
              path: 'createblocking',
              name: 'createblocking',
              component: CreateBlockingEventPage,
              meta: {'requiresAuth': true, title: 'Kalender'}
            }
          ]
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: DashboardPage,
          meta: {'requiresAuth': true, title: 'Terminübersicht'},
          children: [
            {
              path: 'event/:id',
              name: 'dashEvent',
              props: true,
              component: EventPage,
              meta: {'requiresAuth': true, title: 'Terminübersicht'}
            },
            {
              path: 'createevent',
              name: 'dashCreateevent',
              component: CreateEventPage,
              meta: {'requiresAuth': true, title: 'Veranstaltung Erstellen'}
            },
            {
              path: 'blockingevent/:id',
              name: 'dashBlockingevent',
              props: true,
              component: BlockingEventPage,
              meta: {'requiresAuth': true, title: 'Terminübersicht'}
            },
            {
              path: 'createblocking',
              name: 'dashCreateblocking',
              component: CreateBlockingEventPage,
              meta: {'requiresAuth': true, title: 'Terminübersicht'}
            }
          ]
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.auth.loggedIn) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
