import store from './store'
import moment from 'moment'

export function reduceWithAdditional(events, event, key, array) {
  if (event.__typename === 'Event' && event.additionalDates.length !== 0) {
    let datesAsEvents = event.additionalDates.map(ad => ({...event, begin: ad.begin, end: ad.end, isAdditional: true}))
    return [...events, ...datesAsEvents, {...event, hasAdditional: true}]
  }
  return [...events, event];
}

export function mapDisplayEvent(event) {
  // push eventType class
  let classes = [event.__typename]
  let isFull = false
  // push past class
  if (moment(event.end).isBefore(new Date())) {
    classes.push('pastColor')
  }
  if (moment(event.begin).isSame(new Date(), 'day')) {
    classes.push('today')
  }
  if (event.__typename === 'BlockingEvent') {
    classes.push('blockingColor')
  }
  if (event.__typename === 'Event') {
    isFull = event.attendances.filter((a) => a.status === 'APPROVED').length >= event.offering.minUsers
    let myAttendance = event.attendances ? event.attendances.find(a => a.user.id === store.state.auth.user.id) : 0
    if (myAttendance) {
      if (myAttendance.status === 'INTERESTED') {
        classes.push('userColor1')
      } else if (myAttendance.status === 'APPROVED') {
        classes.push('userColor2')
      }
    }
    if (event.canManage) {
      if (!event.isConfirmed) {
        classes.push('alertColor1')
      } else if (!isFull) {
        classes.push('alertColor2')
      }
    }
  }
  return {
    startDate: event.begin,
    endDate: event.end,
    title: event.name,
    ...event,
    classes: classes,
    isFull: isFull
  }
}

export function eventFilter(event) {
  if (event.__typename === 'Event') {
    return store.state.ui.Calendar.hideOfferingIds.indexOf(event.offering.id) === -1
  } else {
    return true
  }
}

export function sortAlpha(a, b, key) {
  let nameA = a[key].toUpperCase() // ignore upper and lowercase
  let nameB = b[key].toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  // namen müssen gleich sein
  return 0
}

import {Observable} from "@apollo/client/core";

export const promiseToObservable = promise =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    );
    return subscriber; // this line can removed, as per next comment
  });