//import axios from 'axios'
import {
  API_LOGIN_URL,
  API_LOGOUT_URL,
  API_SEND_PASSWORD_RESET_URL,
  API_REGISTER_URL,
  API_PASSWORD_RESET_URL
} from '@/config'


const axios = {
  post(URL, body, options = {}) {
    let cookieVal = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
    let opts = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': cookieVal
      }
    }
    if (options.withCredentials) opts['credentials'] = 'include';
    return fetch(URL, opts);
  }
}


export default {
  async login({email, password}) {
    return axios.post(API_LOGIN_URL, {email, password}, {'withCredentials': true})
  },
  async logout() {
    return axios.post(API_LOGOUT_URL, {}, {'withCredentials': true})
  },
  async sendPasswordReset(email) {
    return axios.post(API_SEND_PASSWORD_RESET_URL, {email})
  },
  async resetPassword({email, password, passwordConfirmation, token}) {
    return axios.post(API_PASSWORD_RESET_URL, {
      email,
      password,
      'password_confirmation': passwordConfirmation,
      token
    })
  },
  async registerUser(user) {
    return axios.post(API_REGISTER_URL, {
      'firstName': user.firstName,
      'lastName': user.lastName,
      'email': user.email,
      'password': user.password,
      'permissions': user.permissions
    })
  }
}
