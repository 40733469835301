import apollo from '../../apollo'
import api from './api'
import GET_USER_PROFILE from '../../graphql/Profile.gql'

export default {
  state: {
    loggedIn: !!localStorage.getItem('loggedIn'),
    user: {
      firstName: '',
      lastName: '',
      fullName: '',
      id: '',
      permissions: [],
      offerings: []
    }
  },
  actions: {
    async login(context, {email, password}) {
      // calls the api
      return api.login({email, password})
        .then(() => {
          // wait for set the userstate (including getting the user profile)
          return context.dispatch('updateLoginState', true)
        })
        .catch(() => {
          // throw Login Error
          throw new Error('Login Error')
        })
    },
    async logout(context) {
      // calls the api
      await api.logout()
      await context.dispatch('updateLoginState', false)
      context.commit('onLogout')
    },
    async updateLoginState(context, isLoggedIn) {
      // set loggedIn / loggedOut
      context.commit('setLoggedIn', isLoggedIn)
      if (isLoggedIn) {
        localStorage.setItem('loggedIn', 1)
        // wait for receiving the user profile
        await context.dispatch('getUser')
      } else {
        localStorage.removeItem('loggedIn')
      }
      // reset the apollo store (also when user changes/on reLogin)
      apollo.resetStore()
    },
    async getUser(context) {
      if (!context.state.loggedIn) return
      return apollo.query({
        query: GET_USER_PROFILE,
        // don't cache the user profile
        fetchPolicy: 'no-cache'
      }).then(({data}) => {
        // set the received user profile to store
        context.commit('setProfile', data.Profile)
      })
    },
    sendPasswordReset(context, email) {
      // returns promise of axios api request
      return api.sendPasswordReset(email)
    },
    resetPassword(context, {email, password, passwordConfirmation, token}) {
      // returns promise of axios api request
      return api.resetPassword({email, password, passwordConfirmation, token})
    },
    registerUser(context, user) {
      // returns promise of axios api request
      return api.registerUser(user)
    }
  },
  mutations: {
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn
    },
    setProfile(state, profile) {
      state.user = {
        'id': profile.id,
        'firstName': profile.firstName,
        'lastName': profile.lastName,
        'fullName': profile.fullName,
        'permissions': profile.permissions,
        'offerings': profile.offerings
      }
    },
    onLogout(state) {
      // state.user = {}
      location.reload()
    }
  },
  getters: {
    hasPermission: (state) => (name) => {
      return !!state.user.permissions.find(permission => permission.name === name)
    },
    canSeeSettings: (state) => {
      return state.user.permissions.find(permission => {
        return permission.name === 'edit users' ||
          permission.name === 'edit offerings' ||
          permission.name === 'download database' ||
          permission.name === 'edit mailtemplates'
      })
    },
    canCreateEvents: (state) => {
      return !!state.user.offerings.find(o => o.canManage && o.isManual)
    },
    hasAnyPermssion: (state) => state.user.permissions.length > 0,
    canManageSomething: (state) => {
      return !!state.user.offerings.find(o => o.canManage === true)
    }
  }
}
