import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import {createUploadLink} from 'apollo-upload-client'
import { onError } from "@apollo/client/link/error";


import store from './store/index'
import router from './router/index'
import {API_GRAPHQL_ENDPOINT, API_INIT_CSRF} from './config'
import {promiseToObservable} from "@/utils";

const typePolicies = {
  FormFieldValue: {
    keyFields: ['id', 'value'],
  }
}

const cache = new InMemoryCache({typePolicies /*, dataIdFromObject: object => object.__typename+"_"+object.id */});

const uploadLink = createUploadLink({
  // You should use an absolute URL here
  uri: API_GRAPHQL_ENDPOINT,
  credentials: 'include'
})

const setXSRF = (operation) => {
  let cookieVal = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
  operation.setContext({
    headers: {
      'X-XSRF-TOKEN': cookieVal
    }
  });
}

const xsrfLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the X-CSRF-TOKEN header back.
  setXSRF(operation)
  // Call the next link in the middleware chain.
  return forward(operation);
});

const errorLink = onError(({networkError, graphQLErrors, forward, operation}) => {
   // Former logoutlink
  if (networkError && networkError.statusCode === 401) {
    store.dispatch('logout')
    router.push('/login')
  }

  // Refresh page on unauthenticated
    if (!!graphQLErrors && !!graphQLErrors[0] && graphQLErrors[0].message === "Unauthenticated.") {
      location.reload()
    }

    // Refresh token on mismatch
    if (!!networkError && !!networkError.result && !!networkError.result.message && networkError.result.message === "CSRF token mismatch.") {
      const promise = fetch(API_INIT_CSRF, {credentials: 'include', mode: 'cors'})
      return promiseToObservable(promise).flatMap(() => {
        setXSRF(operation);
        return forward(operation);
      });
    }
  }
)

export default new ApolloClient({
  link: xsrfLink.concat(errorLink).concat(uploadLink),
  cache,
  connectToDevTools: true
})
