<template>
  <div id="app" :class="{'is-overflow-hidden' : isOverflowHidden}" ref="main">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        top: 0
      }
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize() {
        if (window.innerWidth < 1024) {
          this.$store.commit('showListView')
        }
      }
    },
    watch: {
      isOverflowHidden(hidden) {
        if (hidden) {
          this.top = window.scrollY;
          this.$nextTick(() => {
            this.$refs.main.scrollTo(0, this.top)
          })

        } else {
          this.$nextTick(() => {
            window.scrollTo(0, this.top)
          })
        }
      }
    },
    computed: {
      isOverflowHidden() {
        return !!this.$route.matched.find(a => a.name === 'event' || a.name === 'dashEvent' || a.name === 'blockingevent')
      }
    }
  }
</script>

<style lang="scss">
  .modal-open {
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
  .modal-card-foot{
    justify-content: flex-end !important;
  }

  // Import Bulma's core
  @import "~bulma/sass/utilities/_all";
  @import "./assets/tooltip";
  @import "./assets/calendar";
  @import "./assets/colors";

  html, #app, body, .fullheight {
    height: 100%;
    min-height: 100%;
  }

  body, html {
    background-color: $defaultBackgroundColor !important;
  }

  .is-overflow-hidden {
    overflow: hidden
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .bigLabel {
    font-weight: 400;
    margin-bottom: 0.5em;
    color: #363636;
    display: block;
    font-size: 1.35rem;
  }

  .easytable th {
    padding-right: 2em;
  }

  .space1emBottom {
    margin-bottom: 1em;
  }

  .table-has-frame > .table-wrapper > table, .has-frame {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    border-radius: 0 !important;
  }

  .fullWidth {
    width: 100% !important;
  }

  .media-content {
    overflow: initial !important;
  }

  .card-content {
    padding: 0.75rem !important;
  }

  .dropdown-menu {
    min-width: 0 !important;
    z-index: 110 !important;
  }

  .dropdown .background {
    z-index: 100 !important;
  }

  @media screen and (max-width: 1023px) {
    .table-wrapper {
      overflow-x: inherit !important;
    }
  }

  .table-headless table > thead {
    display: none;
  }

  $sizeUnit: rem;
  $marginKey: 'm';
  $paddingKey: 'p';
  $separator: '-';
  $sizes: (
      ('none', 0),
      ('xxs', 0.125),
      ('xs', 0.25),
      ('sm', 0.5),
      ('md', 1),
      ('lg', 2),
      ('xl', 4),
      ('xxl', 8),
  );
  $positions: (
      ('t', 'top'),
      ('r', 'right'),
      ('b', 'bottom'),
      ('l', 'left')
  );

  @function sizeValue($key, $value) {
    @return if($key == 'none', 0, $value + $sizeUnit);
  }

  @each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);
    .#{$marginKey}#{$separator}#{$sizeKey} {
      margin: sizeValue($sizeKey, $sizeValue);
    }
    .#{$paddingKey}#{$separator}#{$sizeKey} {
      padding: sizeValue($sizeKey, $sizeValue);
    }
    @each $position in $positions {
      $posKey: nth($position, 1);
      $posValue: nth($position, 2);
      .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
        margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
      }
      .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
        padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
      }
    }
  }

  @import "./assets/settings";

  @import "~bulma";
  @import "~buefy/src/scss/buefy";

  a.hover-underline:hover {
    text-decoration: underline;
  }

  .fastTransition {
    transition: 0.2s all ease;
  }

  .is-rotating {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .headline {
    font-size: 1.5em;
    font-weight: 400;
    border-bottom: 1px #e1e4e8 solid;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .headline:not(:first-of-type) {
    margin-top: 3rem;
  }

  .headline.is-borderless {
    border: 0;
    margin-bottom: 0.2rem;
  }

  .headline.is-small {
    font-size: 1rem;
    font-weight: 600;
  }

  .headline.is-medium {
    font-size: 1.25rem;
  }

  .headline.is-large{
    font-size: 1.75rem;
  }

  .small-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 25px;
  }

  .very-small-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 11px;
  }


  .dropdown.is-mobile-modal > .dropdown-menu {
    z-index: 150 !important;
  }



   .taginput .taginput-container > .tag, .taginput .taginput-container > .tags {
     height: auto;
     white-space: normal;
     line-height: 2em;
     margin-top: 1px;
     margin-bottom: 1px;
   }
</style>
