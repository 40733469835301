const prod = process.env.NODE_ENV === 'production'
const apibase = prod ? '/' : 'http://juno.localhost/'
export const API_GRAPHQL_ENDPOINT = apibase + 'graphql/management'
export const API_LOGIN_URL = apibase + 'api/login'
export const API_INIT_CSRF = apibase + 'csrf-cookie'
export const API_LOGOUT_URL = apibase + 'api/logout'
export const API_REGISTER_URL = apibase + 'api/register'
export const API_SEND_PASSWORD_RESET_URL = apibase + 'api/password/email'
export const API_PASSWORD_RESET_URL = apibase + 'api/password/reset'

export const UPLOAD_MAX_FILESIZE = 52000000
export const MAX_FILE_UPLOADS = 20
